import request from "@/utils/request";

// 接口：分页获取用户
// 地址：http://${BASE_URL}/user_service/sysUser/list?pageNum={pageNum}&pageSize={pageSize}&keyword={keyword}
export function sysUserList(data) {
  return request({
    url: "/user-service/sysUser/list",
    method: "post",
    data
  });
}
// 接口：根据父级部门ID获取子部门信息
// 地址：http://${BASE_URL}/user_service/sysDept/{id}/list
export function sysDeptList(id) {
  return request({
    url: `/user-service/sysDept/list?id=${id}`,
    method: "get",
  });
}
// 接口：用户禁用or启用
// 地址：http://${BASE_URL}/user-service/sysUser/upOrDown?id=#{id}&status=#{status}
export function upOrDown(data) {
  return request({
    url: `/user-service/sysUser/upOrDown?id=${data.id}&status=${data.status}`,
    method: "post",
    data
  });
}
// 接口：获取全部角色
// 地址：http://${BASE_URL}/user-service/sysRole/list
export function sysRoleList() {
  return request({
    url: `/user-service/sysRole/list`,
    method: "get",
  });
}
// 接口：更新角色
// 地址：http://${BASE_URL}/user-service/sysRole
export function sysRolePut (data) {
  return request({
      url: "/user-service/sysRole",
      method: 'put',
      data
  })
}
// 接口：新增用户
// 地址：http://${BASE_URL}/user-service/sysUser
export function sysUser(data) {
  return request({
    url: `/user-service/sysUser`,
    method: "post",
    data
  });
}
// 接口：更新用户
// 地址：http://${BASE_URL}/user-service/sysUser
export function sysUserPut (data) {
  return request({
      url: "/user-service/sysUser",
      method: 'put',
      data
  })
}
// 接口：查询门店列表（下拉框）
// 地址：http://${BASE_URL}/goods-service/store/listOfPage2
export function listOfPage2() {
  return request({
    url: `/goods-service/store/listOfPage2`,
    method: "get",
  });
}
// 接口：删除用户
// 地址：http://${BASE_URL}/user-service/sysUser/{id}
export function sysUserDel (id) {
  return request({
      url: `/user-service/sysUser/${id}`,
      method: 'delete'
  })
}